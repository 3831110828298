/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fulfilled, rejected, clean, pending } from 'redux-saga-thunk';
import { localDayjs as dayjs } from 'utils/dateTimeUtils';
import { useToasts } from 'react-toast-notifications';
import {
  Spinner,
  SemesterAllocation,
  SemesterAllocationModal,
} from 'components';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';

import { connect } from 'react-redux';

const tomorrowDate = dayjs().add(1, 'd');

const tabs = [
  { name: true, label: 'Ja', className: 'accept' },
  { name: false, label: 'Nej', className: 'reject' },
];

const initError = {
  isValid: true,
  errorMessage: '',
};

const getStartEndDates = (dateFrom, dateTo) => {
  const data = {
    dateFrom,
    dateTo,
  };
  const startDayDiff = dayjs(dateFrom).diff(dayjs().format('YYYY-MM-DD'), 'd');
  // const endDayDiff = dayjs(tomorrowDate.format('YYYY-MM-DD')).diff(dateTo, 'd');

  if (startDayDiff < 0) {
    data.dateFrom = dayjs().format('YYYY-MM-DD');
  }
  return data;
};
function SemesterAllocationContainer({
  enableModal,
  isModalVisible,
  isFulfilled,
  isCreateFulfilled,
  editItem,
  isPending,
  onModalClose,
  onSaveSemesterAllocation,
  onUpdateSemesterAllocation,
  onDeleteSemesterAllocation,
  onGetSemesterAllocations,
  onCleanState,
}) {
  const { addToast } = useToasts();
  const [startDate, setStartDate] = useState(
    enableModal
      ? dayjs(
          getStartEndDates(
            editItem.dateFrom.format('YYYY-MM-DD'),
            editItem.dateTo.format('YYYY-MM-DD'),
          ).dateFrom,
        )
      : tomorrowDate,
  );
  const [endDate, setEndDate] = useState(
    enableModal ? editItem.dateTo : tomorrowDate,
  );
  const [allowResourceRequestChange, setAllowResourceRequestChange] = useState(
    enableModal ? editItem.doAllowResourceRequests : true,
  );
  const [validationError, setValidationError] = useState(initError);

  const validateSemesterData = (startDate, endDate) => {
    const differenceInDays = dayjs(endDate).diff(dayjs(startDate), 'd');
    if (differenceInDays < 0) {
      setValidationError({
        isValid: false,
        errorMessage: '* Ange ett datum som är större än startdatumet',
      });
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    const semesterData = {
      DateFrom: startDate.format('YYYY-MM-DD'),
      DateTo: endDate.format('YYYY-MM-DD'),
      DoAllowResourceRequests: allowResourceRequestChange,
    };

    if (validateSemesterData(semesterData.DateFrom, semesterData.DateTo)) {
      onSaveSemesterAllocation(semesterData);
    }
  };

  const handleOnUpdate = () => {
    const semesterData = {
      DateFrom: startDate.format('YYYY-MM-DD'),
      DateTo: endDate.format('YYYY-MM-DD'),
      DoAllowResourceRequests: allowResourceRequestChange,
      // VacationDayIdentifier: editItem.vacationDayIdentifier,
    };

    if (validateSemesterData(semesterData.DateFrom, semesterData.DateTo)) {
      onUpdateSemesterAllocation(semesterData);
    }
  };

  const handleOnDelete = () => {
    const semesterData = {
      DateFrom: startDate.format('YYYY-MM-DD'),
      DateTo: endDate.format('YYYY-MM-DD'),
      DoAllowResourceRequests: allowResourceRequestChange,
      // VacationDayIdentifier: editItem.vacationDayIdentifier,
    };

    if (validateSemesterData(semesterData.DateFrom, semesterData.DateTo)) {
      onDeleteSemesterAllocation(semesterData);
    }
  };

  const handleStartDatePickerChange = date => {
    setValidationError(initError);
    setStartDate(dayjs(date));
  };

  const handleEndDatePickerChange = date => {
    setValidationError(initError);
    setEndDate(dayjs(date));
  };

  const handleAllowResourceRequestChange = option => {
    setValidationError(initError);
    setAllowResourceRequestChange(option.name);
  };

  useEffect(() => {
    document.title = `Längre ledighet - TFV Tolkportall`;
    if (isFulfilled) {
      if (enableModal) {
        onModalClose();
      }
      onGetSemesterAllocations();
    }
    if (isCreateFulfilled) {
      addToast(
        'Ledigheten är inlagd. Du kan överblicka och ändra/ta bort ledigheten i kalendern.',
        {
          appearance: 'success',
        },
      );
    }
    onCleanState();
  }, [isFulfilled, isCreateFulfilled]);

  return (
    <div>
      {enableModal ? (
        <SemesterAllocationModal
          isVisible={isModalVisible}
          allowResourceRequestChange={allowResourceRequestChange}
          onAllowResourceRequestChange={handleAllowResourceRequestChange}
          onDelete={handleOnDelete}
          tabs={tabs}
          selectedStartDate={startDate.toDate()}
          selectedEndDate={endDate.toDate()}
          validationError={validationError}
          onSave={handleOnUpdate}
          onClose={onModalClose}
          onStartDatePickerChange={handleStartDatePickerChange}
          onEndDatePickerChange={handleEndDatePickerChange}
        />
      ) : (
        <SemesterAllocation
          allowResourceRequestChange={allowResourceRequestChange}
          tabs={tabs}
          selectedStartDate={startDate.toDate()}
          selectedEndDate={endDate.toDate()}
          validationError={validationError}
          onAllowResourceRequestChange={handleAllowResourceRequestChange}
          onSave={handleOnSave}
          onStartDatePickerChange={handleStartDatePickerChange}
          onEndDatePickerChange={handleEndDatePickerChange}
        />
      )}
      {isPending && <Spinner />}
    </div>
  );
}

SemesterAllocationContainer.propTypes = {
  onModalClose: PropTypes.func,
  onSaveSemesterAllocation: PropTypes.func,
  onGetSemesterAllocations: PropTypes.func,
  onCleanState: PropTypes.func,
  enableModal: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  isPending: PropTypes.bool,
  isCreateFulfilled: PropTypes.bool,
  editItem: PropTypes.shape({
    dateFrom: PropTypes.shape({}),
    dateTo: PropTypes.shape({}),
    doAllowResourceRequests: PropTypes.bool,
    vacationDayIdentifier: PropTypes.string,
  }),
};

SemesterAllocationContainer.defaultProps = {
  onModalClose: () => {},
  onSaveSemesterAllocation: () => {},
  onGetSemesterAllocations: () => {},
  onCleanState: () => {},
  enableModal: false,
  isModalVisible: false,
  isFulfilled: true,
  isCreateFulfilled: false,
  isPending: false,
  editItem: {
    dateFrom: new Date(),
    dateTo: new Date(),
    doAllowResourceRequests: true,
    vacationDayIdentifier: '',
  },
};

const mapDispatchToProps = dispatch => ({
  onCleanState: () => {
    dispatch(clean('createSemesterAllocationCreate'));
    dispatch(clean('deleteSemesterAllocationDelete'));
    dispatch(clean('updateSemesterAllocationCreate'));
  },
  onSaveSemesterAllocation: data => {
    dispatch(resourceCreateRequest('createSemesterAllocation', data));
  },
  onUpdateSemesterAllocation: data => {
    dispatch(resourceCreateRequest('updateSemesterAllocation', data));
  },
  onGetSemesterAllocations: () => {
    // const date = dayjs(new Date()).format('YYYY-MM-DD');
    const params = {};
    dispatch(
      resourceListReadRequest(
        'semesterAllocations',
        'VacationDays',
        params,
        true,
      ),
    );
  },
  onDeleteSemesterAllocation: data => {
    dispatch(resourceDeleteRequest('deleteSemesterAllocation', data));
  },
});
const mapStateToProps = state => ({
  isPending:
    pending(state, 'createSemesterAllocationCreate') ||
    pending(state, 'deleteSemesterAllocationDelete') ||
    pending(state, 'updateSemesterAllocationCreate'),
  isFulfilled:
    fulfilled(state, 'createSemesterAllocationCreate') ||
    fulfilled(state, 'deleteSemesterAllocationDelete') ||
    fulfilled(state, 'updateSemesterAllocationCreate'),
  isCreateFulfilled: fulfilled(state, 'createSemesterAllocationCreate'),
  isFailed:
    rejected(state, 'createSemesterAllocationCreate') ||
    rejected(state, 'deleteSemesterAllocationDelete') ||
    rejected(state, 'updateSemesterAllocationCreate'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SemesterAllocationContainer);
