/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Login } from 'containers';
import './style.scss';
import { NavigationLink } from 'components';

function AuthenticationPage(props) {
  return (
    <>
      <Login location={props.location} />
      <NavigationLink
        to="/forgot-password"
        className="tv-authentication-page__Link"
        text="Återställ lösenord"
      />
      <a
        className="tv-authentication-page__accessibility-statement-link"
        tabIndex={0}
        href="https://www.tolkformedlingvast.se/om-tfv/om-webbplatsen/tillganglighetsredogorelse---app-och-portaler.html"
        rel="noreferrer"
        target="_blank"
        aria-label="Tillgänglighetsredogörelse"
      >
        Tillgänglighetsredogörelse
      </a>
    </>
  );
}

AuthenticationPage.propTypes = {};

export default AuthenticationPage;
