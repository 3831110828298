import React, { useContext, useEffect, useState } from 'react';
import {
  TabButtonRow,
  CalendarRangeNavigator,
  MonthCalendar,
  WeekCalendar,
  DayCalendar,
  Button,
  Loader,
  Translate,
} from 'components';
import {
  ExceptionAvailabilityContainer,
  SemesterAllocationContainer,
} from 'containers';
import CalendarContext from 'context/CalendarContext';
import './style.scss';
import { currentDate } from 'utils/dateTimeUtils';

const tabs = [
  { name: 'month', label: 'Månad', className: 'month' },
  { name: 'week', label: 'Vecka', className: 'week' },
  { name: 'day', label: 'Dag', className: 'day' },
];

const selectedCalendar = {
  month: <MonthCalendar key="monthCalendar-1" />,
  week: <WeekCalendar />,
  day: <DayCalendar singleDay />,
};

function CalendarLayout() {
  const {
    selectedCalendarType,
    setCalendarType,
    calendarDateRange,
    handleCalendarTravel,
    isLoading,
    onTodayClick,
    toggleFilters,
    removeActiveExceptionAvailability,
    activeExceptionAvailability,
    activeSemesterAllocation,
    removeActiveSemesterAllocation,
  } = useContext(CalendarContext);

  const [navigatorText, setNavigatorText] = useState(null);
  const [
    displayExceptionAvailabilityModal,
    setDisplayExceptionAvailabilityModal,
  ] = useState(false);
  const [
    displaySemesterAllocationModal,
    setDisplaySemesterAllocationModal,
  ] = useState(false);

  const handleCloseExceptionModal = () => {
    setDisplayExceptionAvailabilityModal(false);
    removeActiveExceptionAvailability();
    localStorage.setItem('manageAvailability', false);
  };

  const handleCloseSemesterAllocationModal = () => {
    setDisplaySemesterAllocationModal(false);
    removeActiveSemesterAllocation();
  };

  useEffect(() => {
    if (selectedCalendarType === 'week') {
      setNavigatorText(calendarDateRange.start.format('[Vecka] W YYYY'));
    } else if (selectedCalendarType === 'month') {
      setNavigatorText(calendarDateRange.startMonth.format('MMMM YYYY'));
    } else {
      setNavigatorText(calendarDateRange.start.format('D MMMM YYYY'));
    }
  }, [calendarDateRange]);

  useEffect(() => {
    if (activeExceptionAvailability?.exceptionIdentifier) {
      setDisplayExceptionAvailabilityModal(true);
    }
  }, [activeExceptionAvailability]);

  useEffect(() => {
    if (activeSemesterAllocation?.vacationDayIdentifier) {
      setDisplaySemesterAllocationModal(true);
    }
  }, [activeSemesterAllocation]);

  useEffect(() => {
    const manageAvailability = localStorage.getItem('manageAvailability');
    if (manageAvailability === 'true') {
      setDisplayExceptionAvailabilityModal(true);
    }
  }, []);
  return (
    <div className="tv-calendar__container">
      <div className="tv-calendar__navigation-container">
        <CalendarRangeNavigator
          text={navigatorText}
          onForwardClick={() => handleCalendarTravel('forward')}
          onBackwardClick={() => handleCalendarTravel('backward')}
        />
        <TabButtonRow
          tabs={tabs}
          hideTabInMobileview={tabs[1]}
          activeTab={selectedCalendarType}
          onTabClick={setCalendarType}
        />
        <Button
          label={Translate({ content: 'calendar.today' })}
          className="tv-calendar__naviagte-today"
          palette="outline"
          onClick={() => onTodayClick()}
        />
        <Button
          label={Translate({ content: 'calendar.submitTimeForAvailability' })}
          className="tv-calendar__availability-exception-button"
          onClick={() => setDisplayExceptionAvailabilityModal(true)}
        />
      </div>
      {isLoading && <Loader />}
      {selectedCalendar[selectedCalendarType]}
      <div className="tv-calendar__mobile-footer">
        <Button
          label={Translate({ content: 'calendar.filter' })}
          palette="outline"
          onClick={() => {
            toggleFilters(true);
          }}
          onKeyDown={e => (e.key === 'Enter' ? toggleFilters(true) : null)}
        />
        <Button
          label={Translate({ content: 'calendar.submitTimeForAvailability' })}
          onClick={() => setDisplayExceptionAvailabilityModal(true)}
        />
      </div>
      {displayExceptionAvailabilityModal && (
        <ExceptionAvailabilityContainer
          isVisible={displayExceptionAvailabilityModal}
          onClose={handleCloseExceptionModal}
          editItem={activeExceptionAvailability}
        />
      )}
      {displaySemesterAllocationModal && (
        <SemesterAllocationContainer
          enableModal
          isModalVisible={displaySemesterAllocationModal}
          onModalClose={handleCloseSemesterAllocationModal}
          editItem={activeSemesterAllocation}
        />
      )}
    </div>
  );
}

export default CalendarLayout;
